#home-root {
    height: 100%;
    width: 100%;
    display: block;
    text-align: center;
    top: 0px;
    left: 0px;
    box-sizing: content-box;
    padding-bottom: 1000px;
    overflow-x: hidden;
}

.header-home {
    position: relative;
    width: 100vw;
    height: 60vh;
    display: flex;
    justify-content: center;
}

.text-home-anim {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100%;
    font-family: sans-serif;
    font-weight: 600;
    font-size: 60px;
}

#p1,
#p3,
#p2 {
    position: absolute;
    color: rgb(0, 167, 167);
}

#p1 {
    animation: p1 1s forwards;
}

#p2 {
    animation: p2 1s 1s forwards;
    opacity: 0;
}

#p3 {
    animation: p3 1s 2s forwards;
    opacity: 0;
}

.sub-conteiner {
    position: absolute;
    bottom: 0px;
    font-size: 45px;
    width: 0vw;
    margin: 0px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    overflow: hidden;
    animation: bottom-text 1s 3s forwards linear;
}

.sub {
    position: absolute;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: monospace;
    color: rgb(15, 78, 78);
}

@keyframes p1 {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes p2 {
    from {
        opacity: 0;
        margin-top: 0px;
    }
    to {
        margin-top: 15vh;
        opacity: 1;
    }
}

@keyframes p3 {
    from {
        opacity: 0;
        margin-top: 15vh;
    }
    to {
        margin-top: 30vh;
        opacity: 1;
    }
}

@keyframes parte2-display {
    from {
        width: 0px;
    }
    to {
        width: 100%;
    }
}

@keyframes bottom-text {
    from {
        width: 0vw;
    }
    to {
        width: 100vw;
    }
}

#top {
    position: relative;
    height: 100%;
}

#background-top {
    position: absolute;
    background-color: black !important;
    background-image: url('./assets/img2.jpg') !important;
    width: 100vw;
    height: 100%;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-attachment: fixed !important;
    background-position: center !important;
    filter: brightness(0.15)
}

#header-home {
    position: relative;
    padding-top: 50px;
    display: block;
    height: 100vh;
    color: white;
    width: 100%;
}

#header-home img {
    display: none;
    margin-top: 20px;
    height: 70vh;
    width: 100vw;
}

#header-text {
    display: block;
    margin-top: 20vh;
    position: relative;
    width: 100vw;
    height: 50vh;
    font-family: sans-serif;
    font-size: 30px;
    color: rgb(255, 255, 255);
    font-weight: 900;
}


/*
#header-buttons {
    background: rgb(26, 25, 25);
    height: 25vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

#header-buttons button {
    background: blue;
    /*linear-gradient(45deg, teal, rgb(0, 105, 119));
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 2px blue;
    border-radius: 50px;
    box-shadow: 0px 0px 10px blue;
    color: white;
    width: 20vw;
    height: 50px;
    font-weight: 900;
    font-size: 20px;
    animation: buttons-header 3s linear infinite;
}

#header-buttons button+button {
    background: teal;
    border: solid 2px teal;
    box-shadow: 0px 0px 10px teal;
}
*/

#img-rol {
    display: flex;
    align-items: center;
    justify-content: center;
    background: gray;
    position: relative;
    margin: 200px 0px;
    height: 400px;
    margin-left: 2%;
    margin-right: 2%;
    width: 96%;
    border-radius: 9px;
    margin-bottom: 100px;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.8);
    transform-style: preserve-3d;
}

#img-rol img {
    width: 100%;
    height: 400px;
    border-radius: 9px;
    position: absolute;
    animation: img-display 1s;
    transition: all 1s;
    display: block;
}

#img-2 {
    opacity: 0;
}

#img-3 {
    opacity: 0;
}

#conteiner-arrow {
    width: 85vw;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#conteiner-arrow button {
    background: none;
    border: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
}

.text {
    color: black;
    position: relative;
    font-family: sans-serif;
    font-weight: 600;
    font-size: 40px;
    margin: 200px 0px;
}

#topics {
    width: 100vw;
    display: block;
    position: relative;
    background: rgb(63, 62, 62);
}

#subtitles {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgb(19, 18, 18);
    height: 80px;
    width: 100vw;
}

#subtitles button {
    position: relative;
    height: 80px;
    background: transparent;
    border: none;
    width: 100%;
    font-size: 200%;
    color: white;
    font-weight: 700;
}

#subtitles div {
    width: 70%;
}

#subtitles div+div {
    width: 100%;
}

.btn-topics {
    display: flex;
    position: relative;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.line-down {
    position: absolute;
    height: 5px;
    background: linear-gradient(90deg, rgb(0, 179, 119), rgb(0, 207, 190));
    border-radius: 14px;
    transition: width 1s;
    bottom: 22px;
    max-width: 120px;
}

#line-down2 {
    width: 0px;
}

#line-down3 {
    width: 0px;
}

#content-topics {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.content-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content-section section {
    display: block !important;
    position: absolute;
    max-width: 190px;
    width: 20%;
    left: 0px;
    top: 0px;
    min-height: 400px;
    height: 100%;
    background: rgb(27, 27, 27);
}

.content-section section button {
    position: relative;
    height: 60px;
    width: 100%;
    font-size: 20px;
    border: none;
}

#even-btn1 {
    background: teal;
    color: white;
    font-weight: 700;
}

#even-btn2 {
    color: teal;
    font-weight: 700;
    background: transparent;
}

#even-btn3 {
    color: teal;
    font-weight: 700;
    background: transparent;
}

#rest-btn1 {
    background: teal;
    color: white;
    font-weight: 700;
}

#rest-btn2 {
    color: teal;
    font-weight: 700;
    background: transparent;
}

#rest-btn3 {
    color: teal;
    font-weight: 700;
    background: transparent;
}

#cont-btn1 {
    background: teal;
    color: white;
    font-weight: 700;
}

#cont-btn2 {
    color: teal;
    font-weight: 700;
    background: transparent;
}

#cont-btn3 {
    color: teal;
    font-weight: 700;
    background: transparent;
}

#section1,
#section2,
#section3 {
    display: flex;
    justify-content: space-between;
}

#content-section-2 {
    display: none;
}

#content-section-3 {
    display: none;
}

.circle {
    width: 10px;
    border: solid white 2px;
    border-radius: 50%;
    height: 10px;
    background: transparent;
    margin-left: 5px;
    margin-right: 5px;
}

#circle-1 {
    background: white;
}

#position-scroll {
    position: absolute;
    display: flex;
    justify-content: center;
    align-content: center;
    bottom: 20px;
}

.text-box {
    width: 80%;
    margin: 2%;
    margin-left: auto;
    overflow: hidden;
    box-shadow: 0px 0px 20px rgb(12, 11, 11);
    padding: 0px 10%;
    min-height: 400px;
}

#content-section-3 {
    display: none;
}

.title-topics {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 30%;
    margin-right: 30%;
    margin-bottom: 30px;
    margin-top: 20px;
    color: white;
}

.title-topics img {
    width: 60%;
    height: 60%;
    max-width: 100px;
    max-height: 100px;
}

.text-box p {
    font-size: 20px;
    text-align: justify;
}

#even-2,
#cont-2,
#rest-2 {
    display: none;
}

#even-3,
#cont-3,
#rest-3 {
    display: none;
}

.quem-somos-conteiner {
    top: 300px;
    position: relative;
    width: 100vw;
    height: 600px;
}

.background-content {
    position: absolute;
    width: 200vw;
    height: 100%;
    /* background: url('./assets/img.jpg');*/
    background-color: black;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: contain;
    left: -200px;
    top: 100px;
    transform: rotate(10deg);
    filter: brightness(0.1);
    overflow: hidden;
    background-color: black
}

.img-quem-somos {
    position: relative;
    height: 800px;
    width: 100%;
    top: -150px;
    background: url('./assets/img2.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    transform: rotate(-10deg);
}

.content-quem-somos {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100%;
}

.title-conteiner-quem-somos {
    position: relative;
    color: white;
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#title-quem-somos {
    width: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    font-family: sans-serif;
    color: teal;
    text-shadow: 0px 10px 50px teal;
}

#title-quem-somos p {
    animation: title-display 2s;
}

.space {
    margin: 0px 10px;
}

.text-quem-somos {
    position: relative;
    color: white;
    width: 90vw;
    max-width: 500px;
    left: 0px;
    top: 50px;
    margin: 0px auto;
    color: gray;
    font-weight: 500;
    font-family: sans-serif;
}

@keyframes title-display {
    from {
        margin-left: -200px;
    }
    to {
        margin-left: 0px;
    }
}

#call-hana-assistant {
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    background: transparent;
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    z-index: 4;
    transition: all;
    animation: call-hana-display 1s;
}

#conteiner-hana-assistant {
    position: fixed;
    bottom: 0px;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    display: none;
    align-items: center;
    transition: width 2s;
    z-index: 3;
}

#hana-conteiner {
    position: fixed;
    background: black;
    width: 0vw;
    height: 5vh;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: none;
    transition: width 0.5s;
    animation: hana-display 0.5s linear;
    overflow: hidden;
}

#hana {
    position: absolute;
    height: 80vh;
    width: 80vw;
}

#hana h2 {
    width: 100%;
    height: 30px;
    background: linear-gradient(95deg, rgb(89, 0, 255), royalblue);
    color: white;
    font-family: sans-serif;
}

#plates-conteiner-master {
    width: 100vw;
    position: relative;
    height: 300px;
    margin-top: 150px;
}

#title-master {
    position: absolute;
    left: 0px;
    width: 100vw;
    text-align: center;
    z-index: 1;
    display: none;
    justify-content: center;
    align-items: center;
    height: 40px;
    color: white;
    background: rgb(32, 32, 32);
    font-weight: 600;
    font-family: sans-serif;
}

#title-master p {
    font-size: 20px;
}

#plates-conteiner-sub {
    position: relative;
    height: 100%;
    width: 100vw;
    transition: all 1s;
}

#plates-conteiner {
    position: relative;
    display: flex;
    align-items: center;
    color: teal;
    font-family: sans-serif;
    height: 100%;
    width: 200vw;
    left: 0vw;
    transition: all 1s;
}

.plate-content {
    position: relative;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-bottom: 0px;
    background: transparent;
}

.plate-content h3 {
    z-index: 1;
}

.plate-content div {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgb(24, 24, 24);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
    filter: brightness(0.2);
}

#background-plate2 {
    background-image: url('./assets/food/img2.jpg');
}

#background-plate1 {
    background-image: url('./assets//food/img1.jpeg');
}

.hiddens {
    height: 100%;
    position: absolute;
    background: black;
    width: 50%;
    animation: hidden-hidded 2s 1s forwards;
}

#hidden-left {
    left: 0px;
}

#hidden-right {
    right: 0px;
}

#svg-anim2,
#svg-anim1 {
    position: absolute;
    height: 100%;
    width: auto;
}

#svg-anim1 {
    right: -5px;
}

#svg-anim2 {
    left: -5px;
    transform: rotateY(180deg);
}

@keyframes hidden-hidded {
    from {
        width: 50%;
    }
    to {
        width: 10%;
    }
}

@media only screen and (max-width:500px) {
    .sub-conteiner {
        font-size: 20px;
    }
    .quem-somos-conteiner {
        height: 500px;
    }
    #home-root {
        width: 100vw;
        overflow-x: hidden;
    }
    #topics {
        width: 100vw;
    }
    #header-home {
        height: 100vh;
    }
    #header-buttons {
        position: relative;
        background: rgb(0, 0, 0);
    }
    #header-buttons button {
        position: relative;
        margin-top: 0px;
        width: 30vw;
        height: 40px;
        font-size: 10px;
    }
    #header-text {
        margin: 10px;
        padding: 0px 30px;
        height: 50vh;
        font-size: 22px;
    }
    /*#header-home img {
        position: relative;
        display: block;
        top: -50px;
        height: 90vh;
    }*/
    #img-rol img {
        height: 200px;
    }
    #img-rol {
        height: 200px;
    }
    #test h1 {
        font-size: 15px;
    }
    #test img {
        width: 50px;
        height: 50px;
        margin-left: 15px;
    }
    .text-box {
        position: relative;
        margin: 2%;
        margin-left: 110px;
        padding: 0px 8%;
        width: 290px;
    }
    .title-topics {
        margin: 20px 20px;
    }
    .title-topics img {
        width: 50px;
        height: 50px;
    }
    .content-section section {
        width: 100px;
    }
    .content-section section button {
        width: 100px;
        font-size: 15px;
        font-weight: 500;
    }
    #subtitles button {
        font-size: 20px;
        font-weight: 700;
    }
    .line-down {
        height: 4px;
        background: linear-gradient(90deg, rgb(0, 179, 119), rgb(0, 207, 190));
        border-radius: 14px;
        width: 100%;
    }
    #subtitles {
        padding-left: 10px;
        padding-right: 10px;
        font-weight: 800;
    }
    .text {
        font-size: 20px;
        margin: 50px 70px;
    }
}

@media only screen and (max-height:500px) {
    #header-text {
        margin-top: 10vh;
        width: 100vw;
        font-size: 20px;
    }
    #header-home {
        margin-bottom: -30px;
    }
    #p1,
    #p3,
    #p2 {
        position: absolute;
        margin-top: 0px;
    }
    .sub-conteiner {
        font-size: 15px;
    }
}

@keyframes hana-display {
    0% {
        width: 0vw;
        height: 30px;
    }
    50% {
        width: 80vw;
        height: 30px;
    }
    100% {
        width: 80vw;
        height: 80vh;
    }
}

@keyframes buttons-header {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

#redirect-dom-plates {
    position: relative;
    height: 50px;
}

@keyframes img-display {
    from {
        opacity: 0;
        transform: translateZ(-30px);
    }
    to {
        transform: translateZ(0px);
        opacity: 1;
    }
}

@keyframes call-hana-display {
    0% {
        bottom: -200px;
    }
    50% {
        bottom: 70px;
    }
    60% {
        bottom: 10px;
    }
    70% {
        bottom: 35px;
    }
    100% {
        bottom: 10px;
    }
}