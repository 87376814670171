#top {
    background: black;
    padding-bottom: 50px;
}

#header-conteiner {
    position: fixed;
    display: block;
    text-align: center;
    width: 100vw;
    height: 40px;
    top: 0px;

    z-index: 5;
}

#menu-conteiner {
    position: absolute;
    width: 40px;
    height: 100%;
}
#mesa-info-value {
    margin-left: 5px;
}

#mesa-info {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.7);
    position: absolute;
    text-decoration: none;
    top: 0px;
    right: 0px;
    height: 100%;
    width: auto;
    font-size: 18px;
    
    border: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
    font-family: sans-serif;
    font-weight: 500;
}

#title-header-menu {
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: sans-serif;
    color: rgb(0, 0, 255);
    animation: title-menu 5s linear;
}

.menu-lines {
    position: relative;
    width: 30px;
    height: 2px;
    background: white;
    margin-top: 6px;
    padding-top: 4px;
    margin-left: 8px;
    transition: transform linear 0.3s;
}

#menu-root {
    opacity: 1 !important;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 0px;
    background: rgba(0, 0, 0, 1);
    border: 3px solid black;
    border-top: none;
    transition: height 1s;
    overflow: hidden;
    z-index: 5;
    padding-top: 30px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.header-menu {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.header-menu div {
    position: relative;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    background: radial-gradient(60px, rgb(98, 0, 255), rgb(89, 0, 255));
    box-shadow: 0px 0px 30px rgb(89, 0, 255);
    border-radius: 5px;
    font-size: 1em;
    transition: background 0.2s;
}

.box-info {
    display: none;
    justify-content: center;
    position: absolute;
    bottom: 10px;
    color: teal;
    background: gray;
    padding: 1px 2px;
    border-radius: 3px;
    opacity: 0;
    transition: opacity 2s;
}

.box-info span {
    position: absolute;
    width: 5px;
    height: 5px;
    background: gray;
    transform: rotate(45deg);
    top: -2.5px;
}

.header-menu div:hover .box-info {
    display: flex;
    opacity: 1;
}

.header-menu div:hover {
    background: rgba(0, 0, 0, 0.35);
}

.list-menu {
    position: relative;
    text-align: center;
    display: block;
    height: 40vh;
    width: 100%;
    margin: 30px 0px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 20px;
    padding: 30px 10px;
}

.list-menu li {
    position: relative;
    display: flex;
    align-items: center;
    list-style-type: none;
    cursor: pointer;
}

.list-menu li+li {
    margin-top: 20px;
}

.link {
    font-family: sans-serif;
    font-size: 20px;
    font-weight: 800;
    text-decoration: none;
    z-index: 4;
    width: 100%;
    text-align: center;
    color: rgb(89, 0, 255);
    transition: transform 0.4s;
}

.link:visited {
    color: rgb(89, 0, 255)
}

.link:hover {
    transform: scale(1.3);
    background: rgba(0, 0, 0, 0.7);
    color: white;
}


/*.list-menu li h3:hover .line-left { JAVASCRIPT
  width: 100%;
}*/

.metadados {
    position: absolute;
    bottom: 0px;
    display: flex;
    width: 100%;
    height: 40px;
    background: rgb(22, 22, 22);
    color: rgb(83, 1, 207);
    justify-content: space-around;
    font-family: sans-serif;
    font-weight: 600;
    align-items: center;
    font-size: 13px;
}

@media only screen and (max-width:500px) {
    #mesa-info {
        right: 0px;
    }
    #menu-root {}
}

@media only screen and (max-height:450px) {
    .list-menu {
        overflow: scroll;
        overflow-x: hidden;
        overflow-y: content;
    }
    #mesa-info{
        right: 0px;
    }
}

@keyframes title-menu {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}