.conteiner-cube {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25vh;
    width: 100vw;
    position: absolute;
    box-sizing: border-box;
    animation: rotate 40s linear infinite;
    bottom: 0px;
    perspective-origin: center;
    transform-style: preserve-3d;
    transform-origin: center;
    /*transform: rotate3d(0, 1, 0, 0deg);
    /*Para deixar faces estaticas afim de reparo*/
}

.faces {
    position: absolute;
    width: 100vw;
    height: 25vh;
    background: black;
    user-select: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

#face1 {
    transform: translateZ(50vw)
}

#face2 {
    transform: translateX(-50vw) rotateY(90deg) rotateY(180deg);
    background: black;
}

#face3 {
    transform: translateX(50vw) rotateY(90deg);
}

#face4 {
    transform: translateZ(-50vw) rotateY(180deg);
}

#header-buttons {
    background: rgb(26, 25, 25);
    height: 25vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

#header-buttons button {
    position: relative;
    background: blue;
    /*linear-gradient(45deg, teal, rgb(0, 105, 119));*/
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 2px blue;
    border-radius: 50px;
    box-shadow: 0px 0px 10px blue;
    color: white;
    width: 20vw;
    height: 50px;
    font-weight: 900;
    font-size: 20px;
    animation: buttons-header 3s linear infinite;
    overflow: hidden;
}

#header-buttons button+button {
    background: teal;
    border: solid 2px teal;
    box-shadow: 0px 0px 10px teal;
}

.calendario {
    height: 20vh;
    width: 100vw;
    position: relative;
    display: block;
    text-align: center;
    border: 3px solid rgb(89, 0, 255);
    background: black;
    color: white;
    border-radius: 3px;
}

#today {}

#title-c-1 {
    background: rgb(89, 0, 255);
    padding: 3px 10px;
    font-size: 18px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    font-weight: 600;
}

#circle-status {
    position: relative;
    height: 10px;
    width: 10px;
    background: rgb(37, 187, 0);
    box-shadow: 0px 0px 8px rgb(37, 187, 0);
    border-radius: 50%;
    margin: 5px 5px;
}

.status {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
}

#next-event div {
    display: block;
}

#next-event {
    width: 100%;
    position: absolute;
    background: rgb(22, 22, 22);
    display: block;
    bottom: 0px;
}

#name-n {
    font-weight: 600;
}

#conteiner-welcome {
    color: rgb(89, 0, 255);
    width: 100vw;
    height: 25vh;
    font-size: 30px;
    font-weight: 800;
    animation: welcome 5s linear;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.welcome {
    position: absolute;
    width: 200px;
    text-shadow: 0px 20px 70px rgb(89, 0, 255);
}

@media only screen and (min-width:700px) {
    .calendario {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    #next-event {
        position: relative;
        width: 50%;
        height: 100%;
        right: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    #today {
        width: 50%;
        position: relative;
        left: 0px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .title-c {
        position: absolute;
        top: 0px;
        background: rgb(89, 0, 255);
        padding: 3px 10px;
        font-size: 18px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        font-weight: 600;
    }
    #title-c-2 {
        margin-right: 3.5vw;
        margin-left: -3.5vw;
    }
}

@media only screen and (max-height:400px) {
    .calendario {
        height: 25vh;
    }
    .status,
    .t-c-content {
        margin-top: 35px;
    }
}

@keyframes rotate {
    0% {
        transform: rotate3D(0, 1, 0, 0deg)
    }
    10% {
        transform: rotate3D(0, 1, 0, 0deg)
    }
    20% {
        transform: rotate3D(0, 1, 0, 90deg)
    }
    30% {
        transform: rotate3D(0, 1, 0, 90deg)
    }
    40% {
        transform: rotate3D(0, 1, 0, 180deg)
    }
    50% {
        transform: rotate3D(0, 1, 0, 180deg)
    }
    60% {
        transform: rotate3D(0, 1, 0, 270deg)
    }
    80% {
        transform: rotate3D(0, 1, 0, 270deg)
    }
    90% {
        transform: rotate3D(0, 1, 0, 360deg)
    }
    100% {
        transform: rotate3d(0, 1, 0, 360deg)
    }
}

@keyframes welcome {
    0% {
        width: 0vw;
    }
    20% {
        width: 0vw;
    }
    50% {
        transform: scale(1);
        color: rgb(89, 0, 255);
    }
    70% {
        width: 100vw;
        transform: scale(1.5);
        color: white;
    }
    100% {
        width: 100vw;
        transform: scale(1);
        color: rgb(89, 0, 255);
        ;
    }
}